import React from "react";
import Header2 from "../components/Header/Header2";
import Welcome2 from "../components/Welcome/Welcome4";
import About2 from "../components/About/About4";
import Energia from "../components/Energia/Energia";
import Engenharia from "../components/Engenharia/Engenharia";
import Agro from "../components/Agro/Agro";
import Portfolio from "../components/Portfolio/Portfolio";
import Clients from "../components/Clients";
import Contact2 from "../components/Contact/Contact4";
import Newsletter2 from "../components/Newsletter/Newsletter2";
import Footer2 from "../components/Footer/Footer2";
import SimpleSlider2 from "../components/Sliders/Slick-slider2";
import bg_vide from "./../videos/solar.mp4";
function Home2() {
  return (
    <>
      <div id="home" className="full-screen-block">
        <video autoPlay loop muted className="bg_video">
          <source src={bg_vide} type="video/mp4"></source>
        </video>
        <div className="bg-inner-dark"></div>
        <Header2 />
        <SimpleSlider2 />
      </div>
      <Welcome2 />
      <About2 />
      <Engenharia />
      <Energia />
      <Newsletter2 />
      <Agro />
      <Portfolio />
      <Clients />
      <Contact2 />
      <Footer2 />
    </>
  );
}

export default Home2;
