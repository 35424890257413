import React from "react";
function About1() {
  return (
    <>
      <section id="about" className="light">
        <div className="bg-light"></div>
        <div className="block">
          <div className="container">
            <div className="row align-items-center  gx-5">
              <div className="col-lg-6">
                <div className="section-sub-title">
                  <article className="section-title-body">
                    <h1 className="head-title">
                      SOBRE <span>CORPORE GROUP</span>{" "}
                    </h1>
                    <p className="head-text">
                      Um grupo de empreendedores comprometidos com o
                      desenvolvimento sustentável, apaixonados por energia
                      limpa, inovação tecnológica, terra e pela gente que nela
                      labuta.
                      <br />
                      <br />
                      Começamos nossa jornada no setor imobiliário, viabilizando
                      grandes empreendimentos de alto padrão, cuja concepção
                      tinha a preservação do meio ambiente como valor
                      intrínseco.
                      <br />
                      <br />
                      Em 2002, abraçamos a missão de participar do
                      desenvolvimento dos primeiros parques eólicos do Brasil,
                      prestando serviços para a maior geradora de energia limpa
                      do mundo.
                      <br />
                      <br />
                      Nos anos seguintes, expandimos nossa atuação para todo o
                      Nordeste, contribuindo com outros grandes players,
                      ajudando-os a de desenvolver importantes projetos eólicos
                      e fotovoltaicos, o que nos permitiu aprender muito,
                      tornando-nos referência nacional na captação de áreas,
                      regularização fundiária e na assessoria em relações
                      governamentais.
                      <br />
                      <br />
                      Organicamente, a expertise adquirida e os resultados
                      alcançados nos levaram a participar de novos negócios,
                      fornecendo nossas soluções, participando ativamente do
                      desenvolvimento, investindo em iniciativas inovadoras e
                      apoiando startups do setor.
                    </p>
                    <ul className="list-unstyled list-bold d-lg-none d-xl-block">
                      <li>
                        <i className="ion-checkmark"></i> Somos comprometidos
                        com as políticas ambientais
                      </li>
                      <li>
                        <i className="ion-checkmark"></i> Comprometidos com o
                        desenvolvimento social{" "}
                      </li>
                      <li>
                        <i className="ion-checkmark"></i> Comprometidos com a
                        entrega de resultados{" "}
                      </li>
                    </ul>
                  </article>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-img-block">
                  <img
                    alt=""
                    className="img-fluid"
                    src="img/preview/sobre2.jpg"
                  />
                  <div className="about-img-info widget-block dark mb-0">
                    <h4 className="widget-title dark">Apresentação (PDF) </h4>
                    <p> Download nossa apresentação</p>
                    <a
                      href="www.google.com"
                      className="btn-default btn-move active mb-0"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About1;
