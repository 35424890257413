import React from "react";
import SliderThreeItem from "../Engenharia/Slick-three-item";

function Portfolio2() {
  return (
    <>
      <section id="engenharia" className="single-bg">
        <div className="section-block-title">
          <div className="bg-dark"></div>
          <div className="container">
            <div className="section-sub-title center">
              <article className="section-title-body white">
                <h1 className="head-title">
                  <span>ENGENHARIA</span> 360
                </h1>
              </article>
              <p className="widget-block relative">
                Após duas décadas de muito trabalho, adquirimos ampla expertise
                no que chamamos de “engenharia 360”: a utilização integrada dos
                melhores recursos de engenharia, tecnologia e direito, que,
                somada ao *feeling* que só regionalidade proporciona, no garante
                maior assertividade no diagnóstico dos problemas, segurança na
                tomada de decisões e eficiência na adoção de soluções,
                permitindo a obtenção de resultados com máxima segurança
                jurídica e prazos recordes.
                <br />
                <br />
                No setor elétrico - segmento que mais absorveu nossas soluções -
                atuamos na promoção de projetos eólicos e fotovoltaicos, na fase
                mais árdua do negócio: captação de áreas e regularização
                fundiária.
                <br />
                <br />
                Somente nos últimos 10 anos, concentramos nossos esforços na
                viabilização de mais de 7GW em projetos no segmento de Geração
                Centralizada, prestando serviços para os maiores players do
                setor elétrico global.
                <br />
                <br />
                Alguns dos projetos que têm a Corpore em seu DNA:
              </p>
            </div>
          </div>
          <div className="container">
            <div className="carousel-with-dots gap-30">
              <SliderThreeItem />
            </div>
            <div className="clearfix h50"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio2;
