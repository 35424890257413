import React from "react";
import SliderThreeItem from "../Sliders/Slick-three-item";

function Portfolio2() {
  return (
    <>
      <section id="agro" className="single-bg">
        <div className="section-block-title">
          <div className="bg-dark"></div>
          <div className="container">
            <div className="section-sub-title center">
              <article className="section-title-body white">
                <h1 className="head-title">
                  <span>AGRO</span> NEGÓCIOS
                </h1>
              </article>
              <p className="widget-block relative">
                Não bastasse a satisfação de contribuir com importantes
                resultados para a descarbonização da economia, o desenvolvimento
                de projetos de geração de energia limpa nos presenteia
                diariamente com a convivência com o homem do campo, haja vista
                os maiores projetos estarem localizados em zona rural.
                <br />
                <br />
                Como resultado dessa frutífera convivência, fomos organicamente
                contagiados com essa paixão pela terra, matriz de tudo o que
                realizamos. Em outras palavras, podemos afirmar que
                transformamos homens da terra em geradores de energia renovável
                e fomos por eles transformados em homens da terra.
                <br />
                <br />
                Desde então, passamos a investir em fazendas, destacando-se a
                Fazenda Cachoeira Grande, onde estamos criando um modelo de
                negócio inovador, cujo foco reside no desenvolvimento de
                atividades rurais viáveis do ponto de vista econômico, social e
                ambiental, através do manejo dos recursos naturais de forma
                integrada e sustentável.
                <br />
                <br />
                Considerada um oásis em meio ao “deserto sertanejo”, na Fazenda
                Cachoeira Grande utilizamos uma abordagem integrada, onde os
                diferentes sistemas produtivos se complementam, gerando
                sinergias e benefícios mútuos, em verdadeiro ciclo sustentável.
                Nela, produzimos nossa própria energia, que alimenta o sistema
                de captação de água e de irrigação de toda a produção de
                oliveiras, frutas e de pastagem para o gado, que, por sua vez,
                produz a maior parte do adubo orgânico utilizado para a
                atividade agrícola.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="carousel-with-dots gap-30">
              <SliderThreeItem />
            </div>
            <div className="clearfix h50"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio2;
