import React from "react";
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";

function Footer2() {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row"></div>

          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-8  order-1 col-xs-7 col-sm-8">
                <ul className="footer-icon list-unstyled">
                  <li>
                    <WhatsappShareButton url="#">
                      <WhatsappIcon round={true} size={32}></WhatsappIcon>
                    </WhatsappShareButton>
                  </li>
                </ul>
              </div>

              <div className="col-md-4 col-xs-5 col-sm-4">
                <div className="footer-logo">
                  <img src="img/logo-big-shop1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer2;
