import React from "react";
import { Link } from "react-scroll";
function Welcome4() {
  return (
    <section>
      <div className="block color-scheme-white light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="section-sub-title center">
                <article className="section-title-body">
                  <h1 className="head-title">
                    AGORA SOMOS <span>CORPORE GROUP.</span> EMPREENDEDORES DOS
                    SEGMENTOS DE <span>ENERGIA</span> E <span>TECNOLOGIA</span>{" "}
                    SE UNIRAM PARA FORMAR ALGO MUITO MAIOR!{" "}
                  </h1>
                  <Link
                    to="portfolio"
                    className="btn-default  btn-move"
                    smooth={false}
                    duration={800}
                  >
                    Portfolio
                  </Link>
                  &nbsp;
                  <Link
                    to="contact"
                    className="btn-default active btn-move t1"
                    smooth={false}
                    duration={800}
                  >
                    Contato
                  </Link>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome4;
