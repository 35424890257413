import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Modal from "react-modal";
Modal.setAppElement("#root");
function ContactForm() {
  const [emailSentModalOpen, emailSentModalSetOpen] = useState(false);

  const [formspreeState, formspreeHandleSubmit] = useForm("mzbqkjgo");


  useEffect(() => {
    if (formspreeState.succeeded == true) {
      emailSentModalSetOpen(true);
    }
  }, [formspreeState.succeeded]);

  return (
    <>
      <Modal
        isOpen={emailSentModalOpen}
        onRequestClose={() => {
          emailSentModalSetOpen(false)
        }}
        className="popup-send"
      >
        <div className="block-popup">
          <h4> Olá,</h4>
          <h4>O e-mail foi enviado com sucesso.</h4>
          <h4>Obrigado.</h4>
        </div>
        <span
          onClick={() => { 
            emailSentModalSetOpen(false);
          }}
          className="close-btn"
        ></span>
      </Modal>
      <div id="form-wrapper">
        <div id="form-inner">
          <div id="MainResult"></div>
          <div id="MainContent">

            <form
              id="MyContactForm"
              name="MyContactForm  emailSentModalSetOpe"
              method="post"
              onSubmit={(evt) => {
                formspreeHandleSubmit(evt);
                evt.target.reset();
              }}
            >
              <p className="name">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Seu Nome*"
                />
                {/* <label htmlFor="name" id="nameLb">
                  <span className="error">{NameError.errText}</span>
                </label> */}
              </p>
              <p>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email*"
                />
                {/* <label htmlFor="email" id="emailLb">
                  <span className="error error1">{EmailError.errText}</span>
                </label> */}
              </p>
              <p>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Telefone"
                />
                <label htmlFor="phone" id="phoneLb"></label>
              </p>
              <p className="textarea">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Mensagem*"
                  rows="4"
                ></textarea>
                {/* <label htmlFor="message" id="messageLb">
                  <span className="error">{TextError.errText}</span>
                </label> */}
              </p>
              <div className="clearfix"></div>
              <div className="text-center">
                {formspreeState.submitting == false && formspreeState.errors.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    Ocorreu um erro ao enviar seu formulário. Por favor, verifique os campos e tente novamente.
                  </div>)
                }
                {formspreeState.submitting && (
                  <div className="alert alert-light" role="alert">
                    📨 Enviando seu contato...
                  </div>)
                }
                <input
                  type="submit"
                  className="contact-btn btn-move t2"
                  value="Enviar Messagem"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
