import React, { Component } from "react";
import Slider from "react-slick";
import PortfolioItem from "./../Portfolio/PortfolioItem";
export default class SliderThreeItem extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 400000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider {...settings}>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"Criação de gado guzerá Pura Origem"}
              portfolioInfo={"Agronegócio"}
              portfolioInfo2={
                "Originária da Índia, esta raça é conhecida por sua rusticidade e adaptação a diferentes condições climáticas. Os animais possuem boa resistência a doenças e parasita, apresentando boa conversão alimentar e alto potencial produtivo. Investimos em um manejo adequado, controle sanitário rigoroso e monitoramento constante para assegurar que nossos Guzerás sejam saudáveis e produtivos. Nosso foco reside na produção de sêmen, cria e recria de animais pura origem. "
              }
              portfolioSrc={"img/agro/portfolio-agro-2.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"Cultivo de oliveira"}
              portfolioInfo={"Agronegócio"}
              portfolioInfo2={
                "Árvore de grande longevidade, símbolo de resistência e sabedoria, conhecida por sua resistência a altas temperaturas e a baixas quantidades de água, possuindo um sistema radicular bastante desenvolvido, características que permitem sua adaptação a diferentes tipos de solo e a torna adaptável a regiões áridas e de clima quente, como é o caso do nosso semiárido. Cultivamos as variedade X, Y e Z, utilizando adubos orgânicos e realizando o manejo sustentável das plantas, para a produção de azeite, chá e cerveja artesanal, buscando sinergias na gestão da terra e dos recursos naturais, tornando a fazenda mais produtiva e sustentável."
              }
              portfolioSrc={"img/agro/portfolio-agro-3.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"Cerveja Artesanal Menorah"}
              portfolioInfo={"Agronegócio"}
              portfolioInfo2={
                "Produzida com as folhas das oliveiras cultivadas na Fazenda Cachoeira Grande, a Menorah é uma Cerveja Artesanal Cream Ale de alta fermentação, coloração clara, bem atenuada, equilibrada e saborosa, notas de malte de médio para médio-baixo, com aroma pouco adocicado e baixo amargor. Espuma de baixa a média formação. Uma das principais características dessa cerveja é o processo de fermentação com uso das folhas da oliveira, o que significa que a cerveja contém polifenóis, antioxidantes poderosos."
              }
              portfolioSrc={"img/agro/portfolio-cerveja.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"Chá Artesanal Menorah"}
              portfolioInfo={"Agronegócio"}
              portfolioInfo2={
                "Produzido à partir de folhas das oliveiras cultivadas na Fazenda Cachoeira Grande, o Chá Menorah contêm uma grande quantidade de antioxidantes, como polifenóis e flavonoides, que ajudam a combater os radicais livres e a prevenir o envelhecimento precoce. Além disso, o chá de oliveira também é rico em vitamina C, ferro, cálcio e outras substâncias benéficas para a saúde. "
              }
              portfolioSrc={"img/agro/portfolio-agro-cha.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
        </Slider>
      </>
    );
  }
}
