import React from "react";
import PortfolioBlockModal from "./PortfolioBlockModal";
const portfolioAll = [
  {
    _id: "001",
    src: "img/portfolio/portfolio-canoas.jpg",
    title: "COMPLEXO RENOVÁVEL NEOENERGIA",
    category: "Engenharia",
    info: "Prospecção de áreas",
    info2: "Intermediação imobiliária",
    info3: "Georreferenciamento",
    info4: "Regularização fundiária",
    info5: "Gestão de proprietários",
    info6: "Licenciamento Ambiental",
    info7: "Assessoria governamental",
  },
  {
    _id: "002",
    src: "img/portfolio/engenharia1.jpg",
    title: "NEOENERGIA SANTA LUZIA",
    category: "Engenharia",
    info: "Prospecção de áreas",
    info2: "Intermediação imobiliária",
    info3: "Georreferenciamento",
    info4: "Regularização fundiária",
    info5: "Gestão de proprietários",
    info6: "Licenciamento Ambiental e cultural",
    info7: "Assessoria governamental",
  },
  {
    _id: "003",
    src: "img/portfolio/portfolio-oitis.jpg",
    title: "NEOENERGIA OITIS",
    category: "Engenharia",
    info: "Prospecção de áreas",
    info2: "Intermediação imobiliária",
    info3: "Georreferenciamento",
    info4: "Regularização fundiária",
    info5: "Gestão de proprietários",
    info6: "Assessoria governamental",
    info7: "",
  },
  {
    _id: "004",
    src: "img/portfolio/portfolio-abiai.jpg",
    title: "RESERVA DO ABIAÍ",
    category: "Imobiliário",
    info: "Levantamento de investidores",
    info2: "Intermediação imobiliária",
    info3: "Assessoria governamental",
    info4: "Georreferenciamento",
    info5: "Regularização fundiária",
    info6: "Licenciamento Ambiental",
    info7: "Licenciamento Cultural",
  },
  {
    _id: "005",
    src: "img/portfolio/portfolio-alphaville.jpg",
    title: "ALPHAVILLE PARAÍBA",
    category: "Imobiliário",
    info: "Levantamento de investidores",
    info2: "Intermediação imobiliária",
    info3: "Assessoria governamental",
    info4: "Georreferenciamento",
    info5: "Regularização fundiária",
    info6: "Licenciamento Ambiental",
    info7: "Licenciamento Cultural",
  },
  {
    _id: "006",
    src: "img/portfolio/portfolio-villas.jpg",
    title: "VILLAS PONTA DE CAMPINA",
    category: "Imobiliário",
    info: "Compra da área",
    info2: "Captação de investidores ",
    info3: "Assessoria governamental",
    info4: "Georreferenciamento",
    info5: "Regularização fundiária",
    info6: "Licenciamento Ambiental",
    info7: "Licenciamento Cultural",
  },
];

function Portfolio3() {
  return (
    <>
      <section id="portfolio" className="light">
        <div className="bg-light"></div>
        <div className="block">
          <div className="container">
            <div className="section-sub-title center">
              <article className="section-title-body">
                <h1 className="head-title">
                  ALGUNS <span>PROJETOS</span> REALIZADOS
                </h1>
                <p className="head-text">
                  Abaixo alguns trabalhos realizados pela CORPORE GROUP
                  separados por unidade de negócio.
                </p>
              </article>
            </div>
            <div className="row">
              {portfolioAll.map((portfolio) => (
                <PortfolioBlockModal
                  key={portfolio._id.toString()}
                  value={portfolio._id}
                  portfolioTitle={portfolio.title}
                  portfolioInfo={portfolio.info}
                  portfolioInfo2={portfolio.info2}
                  portfolioInfo3={portfolio.info3}
                  portfolioInfo4={portfolio.info4}
                  portfolioInfo5={portfolio.info5}
                  portfolioInfo6={portfolio.info6}
                  portfolioInfo7={portfolio.info7}
                  portfolioSrc={portfolio.src}
                  portfolioCat={portfolio.category}
                />
              ))}
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </section>
    </>
  );
}

export default Portfolio3;
