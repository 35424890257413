import React, { Component } from "react";
import Slider from "react-slick";
import PortfolioItem from "../Engenharia/PortfolioItem";
export default class SliderThreeItem extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 400000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider {...settings}>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"COMPLEXO RENOVÁVEL NEOENERGIA"}
              portfolioCat={"Engenharia"}
              portfolioInfo={" Prospecção de áreas "}
              portfolioInfo2={" Intermediação imobiliária "}
              portfolioInfo3={" Georreferenciamento "}
              portfolioInfo4={" Regularização fundiária "}
              portfolioInfo5={" Gestão de proprietários "}
              portfolioInfo6={" Licenciamento Ambiental "}
              portfolioInfo7={" Assessoria governamental "}
              portfolioSrc={"img/portfolio/portfolio-canoas.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"NEOENERGIA SANTA LUZIA"}
              portfolioCat={"Engenharia"}
              portfolioInfo={" Prospecção de áreas "}
              portfolioInfo2={" Intermediação imobiliária "}
              portfolioInfo3={" Georreferenciamento "}
              portfolioInfo4={" Regularização fundiária "}
              portfolioInfo5={" Gestão de proprietários "}
              portfolioInfo6={" Licenciamento Ambiental e cultural "}
              portfolioInfo7={" Assessoria governamental "}
              portfolioSrc={"img/portfolio/engenharia1.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
          <div className="item">
            <PortfolioItem
              portfolioUrl={"/"}
              portfolioTitle={"NEOENERGIA OITIS"}
              portfolioCat={"Engenharia"}
              portfolioInfo={" Prospecção de áreas "}
              portfolioInfo2={" Intermediação imobiliária "}
              portfolioInfo3={" Georreferenciamento "}
              portfolioInfo4={" Regularização fundiária "}
              portfolioInfo5={" Gestão de proprietários "}
              portfolioInfo6={" Licenciamento Ambiental "}
              portfolioInfo7={" Assessoria governamental "}
              portfolioSrc={"img/portfolio/portfolio-oitis.jpg"}
              portfolioAlt={"blog"}
            />
          </div>
        </Slider>
      </>
    );
  }
}
