import React from "react";
import { Link } from "react-scroll";
function About2() {
  return (
    <>
      <section id="energia" className="single-bg ab2">
        <div className="section-block-title">
          <div className="bg-dark"></div>
          <div className="container">
            <div className="section-sub-title center">
              <article className="section-title-body white">
                <h1 className="head-title">
                  ENERGIAS <span>RENOVÁVEIS</span>
                </h1>
              </article>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-2 d-flex align-items-center">
                <div className="widget-block relative">
                  <p>
                    Após duas décadas atuando em exitosas parcerias com alguns
                    dos maiores players do mercado, sob o regime de
                    exclusividade, fomos convencidos de que poderíamos ir muito
                    mais longe se nosso know-how pudesse contribuir com o
                    mercado de forma ampla. Por isso, em 2023 decidimos abrir
                    nossas portas para todo o segmento de Geração Centralizada,
                    celebrando novas parcerias e abraçando o desenvolvimento de
                    projetos ainda mais ousados.
                    <br />
                    <br />
                    Como entusiastas do empreendedorismo, nos desafiamos a fazer
                    a diferença também no segmento de Geração Distribuída,
                    através do desenvolvimento de projetos próprios. Nossas
                    primeiras plantas estão sendo desenvolvidas na Paraíba e em
                    Pernambuco, somando, atualmente, 25MW de capacidade.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-lg-1 d-flex">
                <div className="about-img-block">
                  <img
                    className="img-fluid"
                    src="img/preview/energia.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About2;
